<template>
	<div>
		<div class="flex header">
			<h2>违规通知记录</h2>
		</div>
		<div class="table">
			<el-table
					:data="tableData"
					:header-cell-style="{'background':'rgba(0, 103, 166, 0.3)','color': '#ffffff','fontWeight':'bold','textAlign':'center'}"
					height="calc(100vh - 256px)"
					style="width: 100%">
				<el-table-column align="center" show-overflow-tooltip
												 label="发送日期">
					<template slot-scope="scope">
						{{ scope.row.sendDate|formatTimer }}
					</template>
				</el-table-column>
				<el-table-column
						prop="sendBy" align="center" show-overflow-tooltip
						label="操作人">
				</el-table-column>
				<el-table-column
						prop="carNo" align="center" show-overflow-tooltip
						label="车牌号码">
				</el-table-column>
				<el-table-column
						prop="carOwner" align="center" show-overflow-tooltip
						label="车主姓名">
				</el-table-column>
				<el-table-column
						prop="ownerTel" align="center" show-overflow-tooltip
						label="电话号码">
				</el-table-column>
				<el-table-column align="center" show-overflow-tooltip
						label="违规时间">
					<template slot-scope="scope">
						{{ scope.row.copyDate|formatTimer }}
					</template>
				</el-table-column>
				<el-table-column
						prop="copyPark" align="center" show-overflow-tooltip
						label="违规停车场">
				</el-table-column>
				<el-table-column
						prop="copyContent" align="center" show-overflow-tooltip
						label="违规操作">
				</el-table-column>
				<el-table-column
						prop="sendRemark" align="center" show-overflow-tooltip
						label="备注">
				</el-table-column>
			</el-table>
			<el-pagination
					class="pagination"
					@current-change="handleCurrentChange"
					:current-page="page.current"
					:page-size="page.size"
					layout="prev, pager, next, total"
					:total="page.total">
			</el-pagination>
		</div>

	</div>
</template>

<script>
export default {
	name: "violation",
	data() {
		return {
			tableData: [],
			page: {
				current: 1,
				size: 10,
				total: 0
			},

		};
	},
	mounted() {
		this.getData()
	},
	methods: {
		handleCurrentChange(val) {
			this.page.current = val;
			this.getData();
		},
		getData() {
			let params = {
				page: this.page.current,
				size: this.page.size
			};
            this.$get('/car/copy-notice-record/list/page', params).then(res => {
				this.tableData = res.data.rows;
				this.page.total = res.data.total;
			}).catch(err => {
				console.log("err: " + err);
			});

		}
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/table";
</style>
